import React, { useState, useEffect } from "react";
import { FaTrash, FaCheck } from "react-icons/fa";
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import "./AppliedCandidates.css";
import Footer from "../Footer/Footer";
import "../ResponsiveCss/ResponsiveCss.css";
import { update } from "../../apis/IntershipAPI";

const AppliedCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState({});
  const [count, setCount] = useState({});
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("userId");
      const response = await axios.get(
        `https://backend.internsbee.in/api/newemployee/employeeinternship/${id}`
      );

      setCandidates(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const savedCount = JSON.parse(localStorage.getItem('count')) 
    setCount(savedCount);

  }, []);

  const fetchStudentsData = async (id) => {
    try {
      const response = await axios.get(
        `https://backend.internsbee.in/api/newemployee/getstudentdetails/${id}`
      );

      setCandidateDetails(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleApprove = async (id) => {
    try {
      const res = await axios.patch(
        `https://backend.internsbee.in/api/newemployee/updateinternshipstatus/${id}`,
        {}
      );
      if (res.status === 200) {
        alert("Student is Shortlisted.");
      }
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadResume = async (first_name, last_name, url) => {
    try {
      const response = await axios.get(url, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = first_name + '_' + last_name + "_resume.pdf";
      link.click();
      console.log(count)
      const data = {
        ResumeView: count.ResumeView - 1
      }
      const res = await update(data);
      if (res.status === 200) {
        setCount(res.data.data)
        const ResData = res.data.data
        console.log(ResData)

        const countData ={
          searches: ResData.searches,
          internshipEnquiry: ResData.internshipEnquiry,
          verifiedApplication: ResData.verifiedApplication,
          ResumeView: ResData.ResumeView
        }
        localStorage.setItem('count', JSON.stringify(countData));

      }
    } catch (error) {
      console.error("Error downloading the resume:", error);
    }
  };



  const handleDeleteClick = (candidate) => {
    setCandidateToDelete(candidate);
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setCandidateToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `https://backend.internsbee.in/api/studentsdetails/${candidateToDelete._id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        await fetchData();
      } else {
        console.error("Failed to delete shortlisted candidate");
      }
    } catch (error) {
      console.error("Error deleting shortlisted candidate:", error);
    }

    setShowConfirmation(false);
    setCandidateToDelete(null);
  };

  const handleViewMoreClick = (id) => {
    fetchStudentsData(id);
    openModal();
  };

  return (
    <>
      <Navbar />
      <div>
        <div className="displaycontent flex h-screen ">
          <Sidebar />
          <div className=" flex ml-12">
            <div className="abc">
              <div className="mt-4 md:mr-4 flex justify-center mr-8">
                <input
                  type="text"
                  placeholder="Search by Internship title"
                  className="p-2 w-full border-1 border-amber-300 rounded-md text-large"
                />
              </div>
              <h1
                className=" text-xl font-bold mb-4 mt-8"
                style={{ fontFamily: "Arial, sans-serif" }}
              >
                View Applied Candidate Details
              </h1>
              <div className="appliedscroll flex gap-10">
                <div className="table-container">
                  <table className="table text-left">
                    <thead>
                      <tr className="tps">
                        <th className="py-4 px-6 border-b text-l">Sr.No.</th>
                        <th className="py-4 px-6 border-b text-l">
                          Candidates Name
                        </th>
                        <th className="py-4 px-6 border-b text-l">Location</th>
                        <th className="py-4 px-6 border-b font-bold text-l">
                          Internship Tiltle
                        </th>
                        <th className="py-4 px-6 border-b font-bold text-l">
                          View More
                        </th>
                        <th className="py-4 px-6 border-b font-bold text-l">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="pl-0">
                      {candidates.map((candidate, index) => (
                        <tr key={candidate._id}>
                          <td className="py-2 pr-4 border-b text-lg">
                            {index + 1}
                          </td>
                          <td className="py-2 pr-4 border-b text-lg">
                            {candidate.InternName}
                          </td>
                          <td className="py-2 pr-4 border-b text-lg">
                            {candidate.location}
                          </td>
                          <td className="py-2 pr-4 border-b text-lg">
                            {candidate.job_Title}
                          </td>
                          <td className="py-2 pr-4 border-b">
                            <div className="actions">
                              <button
                                className="text-blue-500 hover:text-blue-700 mr-2 text-lg"
                                onClick={() => handleViewMoreClick(candidate.InternId)}
                              >
                                View More
                              </button>
                            </div>
                          </td>
                          <td className="flex justify-around w-64">
                            <div className="flex justify-around text-white rounded-lg bg-green-500 hover:bg-green-00 px-3 py-2 w-28 items-center">
                              <button
                                onClick={() => handleApprove(candidate._id)}
                                className="me-2"
                              >
                                Approve
                              </button>
                              <FaCheck />
                            </div>
                            <div className="flex justify-around text-white rounded-lg bg-red-500 hover:bg-red-600 px-3 py-2 w-28 items-center">
                              <button
                                onClick={() => handleDeleteClick(candidate)}
                                className="me-2"
                              >
                                Reject
                              </button>
                              <FaTrash />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showConfirmation && (
          <div className="confirmation-modal">
            <p>Are you sure you want to delete this candidate?</p>
            <button onClick={handleConfirmDelete}>Yes</button>
            <button className="not-delete" onClick={handleCancelDelete}>
              No
            </button>
          </div>
        )}

        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 max-w-md w-full rounded-md">
              <h2 className="text-xl font-semibold mb-4">Student Details</h2>
              <div>
                <label className="block mb-2">
                  <strong>Name: </strong>
                  {candidateDetails.firstName} {candidateDetails.lastName}{" "}
                </label>
                <hr />
                <label>
                  <strong>Email:</strong> {candidateDetails.email}
                </label>
                <hr />
                <label>
                  <strong>Permanent Address:</strong>{" "}
                  {candidateDetails.permanentaddress}
                </label>
                <hr />
                <label>
                  <strong>City:</strong> {candidateDetails.city}
                </label>
                <hr />
                <label>
                  <strong>District: </strong>
                  {candidateDetails.district}
                </label>
                <hr />
                <label>
                  <strong>Education: </strong>
                  {candidateDetails.education}
                </label>
                <hr />
                <label>
                  <strong>Institute Name:</strong>{" "}
                  {candidateDetails.instituteName}
                </label>
                <hr />
                <label>
                  <strong>Stream: </strong>
                  {candidateDetails.stream}
                </label>
                <hr />
                <label>
                  <strong>Pass Out Year:</strong>{" "}
                  {candidateDetails.passOutYear}
                </label>
                <hr />
                <label>
                  <strong>Key Skills:</strong>{" "}
                  {candidateDetails.keySkills}
                </label>
                <hr />
                <label>
                  <strong>Languages:</strong>{" "}
                  {candidateDetails.languages}
                </label>
                <hr />
                <label>
                  <strong>Experience:</strong>{" "}
                  {candidateDetails.experience}
                </label>
                <hr />
                <label>
                  <strong>Salary Expectations:</strong>{" "}
                  {candidateDetails.salaryExpectations}
                </label>
                <hr />
                <label>
                  <strong>Project Name:</strong>{" "}
                  {candidateDetails.projectName}
                </label>
                <hr />
                <label>
                  <strong>View Resume: </strong>
                  <button
                    className="text-blue-500 underline"
                    onClick={() =>
                      handleDownloadResume(candidateDetails.firstName, candidateDetails.lastName, candidateDetails.student_PDF)
                    }
                  >
                    Download Resume
                  </button>
                </label>
                <hr />
              </div>
              <div className="flex justify-between mt-3">
                <button
                  className="text-black bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-500"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default AppliedCandidates;
