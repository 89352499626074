import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Navbar from "./Navbar";
import LandingPage from "./Landingpagemain";

const LandingpageHome = () => {

 
  return (
    <>
      <div>
        <Navbar />
      </div>
      <div>
        <LandingPage />
      </div>
    </>
  );
};

export default LandingpageHome;
