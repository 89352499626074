import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StudentRoutes from './Student/App';
import EmployerRoutes from './Employer/App';
import LandingpageHome from './LandingPage/LandingpageHome';
import NotFound from './LandingPage/NotFound';
import { EmployerProtectedRoute, StudentProtectedRoute } from './LandingPage/ProtectedRoute';
import Home1 from './Student/Components/Home1';
import Blog from './Student/Components/Blogs/Blogs';
import TPO from './Student/Components/TPO/TPO';
import LandingInternship from './Student/Components/LandingInternship/LandingInternship';
import ResetPass from './Student/Components/RestPass/ResetPass';
import VerifyOtp from './Student/Components/RestPass/VerifyOtp';
import SendVerifyOTP from './Employer/Component/ChangePassword/SendVerifyOTP';
import ResetPassword from './Employer/Component/ChangePassword/ResetPassword';
function App() {
  return (
    <>
      <Router>
        <Routes>

          <Route path="/" element={<LandingpageHome />} />

{/* Students */}
          <Route path="/home" element={<Home1 />} />
          <Route path="/student/blogs" element={<Blog />} />
          <Route path="/student/TPO" element={<TPO />} />
          <Route path="/student/LandingInternship" element={<LandingInternship />} />
          <Route path="/student/resetpass" element={<ResetPass />} />
          <Route path="/student/changepassword/:token" element={<VerifyOtp />} />
          <Route path='/student/*' element={<StudentProtectedRoute element={<StudentRoutes />} />} />


          {/* Employer */}
          <Route path='/employer/*' element={<EmployerProtectedRoute element={<EmployerRoutes />} />} />

          <Route path='/employer/resetpass'element={<SendVerifyOTP/>}/>
           <Route path='/employer/changepassword/:token'element={<ResetPassword/>}/>

          <Route path='*' element={<NotFound />} />
          {/* <Route element={<NotFound />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
