import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../ResponsiveCss/ResponsiveCss.css";
import logo from "../../Assets/contactUs.jpg";
import axios from "axios";
import { config } from "../../service/service";

const ResetPass = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [timer, setTimer] = useState(120);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "otp") {
      setOtp(value);
    }
  };

  const sendOTPHandler = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.backend_url}/api/newstudents/resetotp`,
        { email }
      );
      setLoading(false);
      if (response.status === 200) {
        setOtpSent(true);
        alert("OTP sent successfully");
      } else {
        alert(response.data.msg)
        setErrorMessage(response.data.msg);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred. Please try again later.");
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setOtpLoading(true);
    // Implement your OTP verification logic here
    try {
      // Mocking an API call
      const res =  await axios.post(
        `${config.backend_url}/api/newstudents/verifyresetotp`,
        { email ,otp}
      );
      if(res.status === 200){
        console.log("OTP Verified:", otp);
        alert("OTP Verified successfully");
        const token = res.data.token
        navigate(`/student/changepassword/${token}`);
      }
      else{
        alert(res.data.message)
        setErrorMessage(res.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("OTP verification failed. Please try again.");
    } finally {
      setOtpLoading(false);
    }
  };

  useEffect(() => {
    if (otpSent && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [otpSent, timer]);

  const formatTimer = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
  };

  return (
    <div className="container mx-auto p-4 mt-24">
     

      <div className="flex flex-col items-center justify-evenly sm:flex-row sm:justify-evenly sm:space-x-8 lg:space-x-16">
        <img
          src={logo}
          alt="design"
          className="hidden sm:block sm:w-1/2 lg:w-1/3"
          style={{ maxHeight: "500px" }}
        />

        <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6 space-y-4">
          <h1 className="text-xl font-bold leading-tight text-gray-900 text-center">
            Verify your Email account
          </h1>

          <div className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Your email
              </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={onChangeHandler}
                className={`bg-gray-50 border ${
                  errorMessage ? "border-red-500" : "border-gray-300"
                } text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                placeholder="name@company.com"
              />
              {errorMessage && (
                <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
              )}
            </div>

            <button
              type="button"
              onClick={()=>sendOTPHandler()}
              className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              disabled={loading}
            >
              {loading ? "Sending OTP..." : "Send OTP"}
            </button>
          </div>

          {otpSent && (
            <div className="mt-4 space-y-4">
              <div>
                <label
                  htmlFor="otp"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Enter OTP
                </label>
                <input
                  type="text"
                  name="otp"
                  value={otp}
                  onChange={onChangeHandler}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter OTP"
                />
              </div>
              <button
                onClick={()=>handleVerifyOtp()}
                className="w-full mt-2 text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                disabled={otpLoading}
              >
                {otpLoading ? "Verifying OTP..." : "Verify OTP"}
              </button>
              <p className="text-gray-500 text-sm mt-2">
                Time remaining: {formatTimer(timer)}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
