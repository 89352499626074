import axios from "axios";
const getHeader = () => {
  const token = localStorage.getItem("token");
  return {
    Authorization: token,
  };
};

// Create an Axios instance with default settings
const apiClient = axios.create({
  baseURL: "https://backend.internsbee.in",
  // timeout: 5000,
  headers: getHeader(),
});

// Use an interceptor to dynamically set the headers before each request
apiClient.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    ...getHeader(),
  };
  return config;
}, (error) => {
  return Promise.reject(error);
});
// Custom error handler
const handleError = (error) => {
  if (error.response) {
    console.error("Server responded with an error:", error.response.data);
  } else if (error.request) {
    console.error("No response received:", error.request);
  } else {
    console.error("Error setting up request:", error.message);
  }
  throw error;
};
// POST request with custom headers
const registerAPI = async (data, headers = {}) => {
  try {
    const endpoint = "/api/newemployee/register";
    const response = await apiClient.post(endpoint, data, { headers });
    return response;
  } catch (error) {
    handleError(error);
  }
};
const loginUserNew = async (data, headers) => {
  try {
    const endpoint = `/api/newemployee/login`;
    const response = await apiClient.post(endpoint, data, { headers });
    return response;
  } catch (error) {
    handleError(error);
  }
};
const sendOTP = async (data) => {
  try {
    const endpoint = `/api/newemployee/sendotp`;
    const response = await apiClient.post(endpoint, data);
    return response;
  } catch (error) {
    handleError(error);
  }
};
const verifyOTP = async (data) => {
  try {
    const endpoint = `/api/newemployee/verifyotp`;
    const response = await apiClient.post(endpoint, data);
    return response;
  } catch (error) {
    handleError(error);
  }
};
const sendResetOTP = async (data) => {
  try {
    const endpoint = `/api/newemployee/resetotp`;
    const response = await apiClient.post(endpoint, data);
    return response;
  } catch (error) {
    handleError(error);
  }
};
const verifyResetOTP = async (data) => {
  try {
    const endpoint = `/api/newemployee/verifyresetotp`;
    const response = await apiClient.post(endpoint, data);
    return response;
  } catch (error) {
    handleError(error);
  }
};
const changePassword = async (data, token) => {
  try {
    const endpoint = `/api/newemployee/changepassword/${token}`;
    const response = await apiClient.patch(endpoint, data);
    return response;
  } catch (error) {
    handleError(error);
  }
};
// 
const getEmployee = async () => {
  try {
    const endpoint = `/api/newemployee/getuser`;
    const response = await apiClient.get(endpoint);
    return response;
  } catch (error) {
    handleError(error);
  }
}

const updateEmployee = async (data) => {
  try {
    const endpoint = `/api/newemployee/updateemployee`;
    const response = await apiClient.patch(endpoint, data);
    return response;
  } catch (error) {
    handleError(error);
  }
}
export {
  registerAPI,
  loginUserNew,
  sendOTP,
  verifyOTP,
  sendResetOTP,
  verifyResetOTP,
  changePassword,
  updateEmployee,
  getEmployee
};
