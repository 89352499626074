import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./PackagesPage.css";
import Footer from "../Footer/Footer";
import Logo from '../../Assets/Internsb.png';
import { annuallyPackageAPI, getUserAPI, orderAPI, paymentVerifyAPI } from '../../apis/PaymentAPI';

function AnuallyPackages() {
  const [endDate, setendDate] = useState();
  const [active, setActive] = useState();
  const [token, setToken] = useState(''); // Use state for token

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    setToken(savedToken); // Set the token in state
  }, []);

  const handleSubscribe = async () => {
    // Create order on the server
    const reqdata = {
      amount: 8500, // in rupees
      currency: 'INR',
      receipt: 'receipt#1',
    }
    const { data } = await orderAPI(reqdata);

    const options = {
      key: 'rzp_test_hmT62XLvUL5N9Q', // Enter the Key ID generated from the Dashboard
      amount: data.amount,
      currency: data.currency,
      name: 'Interns Bee',
      description: 'Test Transaction',
      image: Logo,
      order_id: data.orderId,
      handler: async (response) => {
        console.log(response);
        const data = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          amount: reqdata.amount
        };
        const headers = {
          headers: {
            'Authorization': token
          }
        }
        const verifyResponse = await paymentVerifyAPI(data, headers);

        if (verifyResponse.data.success) {
          alert('Payment Successful');

          // update API
          try {
            const data = {};
            const headers = {
              headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('token')
              },
            }
            const response = await annuallyPackageAPI(data, headers);
            localStorage.setItem('count', JSON.stringify(response.data.data))
            window.location.assign('/employer/home');
          } catch (error) {
            console.error('Error updating package:', error);
          }
        } else {
          alert('Payment Verification Failed');
        }
      },
      prefill: {
        name: userDetails.name,
        email: userDetails.email,
        contact: userDetails.number,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#3399cc',
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  const [userDetails,setuserDetails] = useState({
    email:'',
    name:'',
    number:''
  })
  useEffect(() => {
    
    const fetchEndDate = async () => {
      try {
        const header = {
          headers: {
            'Authorization': token
          }
        }
        const res = await getUserAPI(header);
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        };
        const formattedDate = formatDate(res.data.data.package.end_date);
        setendDate(formattedDate);
        setuserDetails({
          number:res.data.data.number,
          email:res.data.data.email,
          name:res.data.data.name,
        })
        setActive(res.data.data.package.active);
      } catch (error) {
        console.error('Error fetching End date:', error);
      }
    }
    fetchEndDate();
  }, []);

  return (
    <>
      <Navbar />
      <section className="flex flex-wrap md:flex-nowrap text-gray-600 body-font">
        <div className="w-full md:w-1/4">
          <Sidebar />
        </div>
        <div className="w-full md:w-3/4 mx-auto">
          <div className="flex flex-col text-center w-full mt-7">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-black">
             Anually Packages
            </h1>
            <div className="flex mx-auto border-2 border-amber-300 rounded overflow-hidden mt-6">
              <Link to="/employer/monthlypackage">
                <button className="py-1 px-4 bg-amber-300 text-black focus:outline-none">
                  Monthly/Pro
                </button>
              </Link>
              <Link to="/employer/anuallypackage">
                <button className="py-1 px-4 text-black focus:outline-none">
                  Anually/Business
                </button>
              </Link>
            </div>
          </div>

          <div className="w-full md:w-1/2 mx-auto">
            <div className="p-2 xl:w-full md:w-1/2 w-full">
              <div className="p-6 rounded-lg border-2 border-amber-300 flex flex-col relative overflow-hidden">
                <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                  BUSINESS
                </h2>
                <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-amber-300">
                  <span>₹8500</span>
                  <span className="text-lg ml-1 font-normal text-black">
                    /yr
                  </span>
                </h1>
                <p className="flex items-center text-gray-600 mb-2">
                  <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.5"
                      className="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                  </span>
                  30 searches
                </p>
                <p className="flex items-center text-gray-600 mb-2">
                  <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.5"
                      className="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                  </span>
                  18 Internship Enquiry
                </p>
                <p className="flex items-center text-gray-600 mb-2">
                  <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.5"
                      className="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                  </span>
                  30 Verified Application
                </p>
                <p className="flex items-center text-gray-600 mb-2">
                  <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.5"
                      className="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                  </span>
                  50 Resume View
                </p>
                <p className="flex items-center text-gray-600 mb-6">
                  <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.5"
                      className="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                  </span>
                  Dedicated CRM
                </p>

                <button
                  onClick={handleSubscribe}
                  className="flex items-center mt-auto text-black bg-amber-300 border-0 py-2 px-4 w-full focus:outline-none hover:bg-amber-500 rounded"
                >
                  Subscribe
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-auto"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </button>
                {active && (
                  <p className="text-xs text-black mt-3">
                    Your Subscription will end on <span className='font-bold text-sm'>{endDate}</span>.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default AnuallyPackages;
