import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Alert from "./Alert/Alert";
import "./SignIn.css";

import { config } from "../service/service";



const Login = ({ closePopup, openRegister }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      navigate("/student/home");
    }
  }, [navigate]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${config.backend_url}/api/newstudents/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (response.status === 200) {
        const responseData = await response.json();
        localStorage.setItem("userId", responseData.user._id);
        localStorage.setItem("token", responseData.user.token);
        localStorage.setItem("email", responseData.user.email);

        const userId = responseData.userId;
        const checkDetailsResponse = await fetch(
          `${config.backend_url}/api/studentsdetails/userId/${userId}`
        );
        const checkDetailsData = await checkDetailsResponse.json();

        if (checkDetailsResponse.ok) {
          navigate("/student/home");
        } else {
          navigate("/student/internship");
        }
      } else if (response.status === 202) {
        const responseData = await response.json();
        setApiError(responseData.message);
      } else {
        const errorData = await response.json();
        console.error("Invalid credentials:", errorData.error);
      }
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const handleSignUpClick = () => {
    closePopup(); // Close the login modal
    openRegister(); // Open the register modal
  };

  return (
    <div className="">
      <div className="">
        <div className="text-left flex flex-col items-center md:flex-row md:justify-center md:px-0">
          <div className="w-auto md:mt-0 md:ml-8 xl:p-0 mt-40">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8 border border-black bg-white">
              <h1 className="text-lg font-bold leading-tight tracking-tight text-black md:text-2xl dark:text-black">
                Sign in to your account
              </h1>
              <form
                onSubmit={onSubmit}
                className="space-y-4 md:space-y-6"
                action="#"
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Student email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={onChangeHandler}
                    className={`bg-gray-50 border ${apiError ? "border-red-500" : "border-black"
                      } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-black dark:placeholder-gray-700 dark:text-black dark:focus:ring-black dark:focus:border-black`}
                    placeholder="name@company.com"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Student Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                    onChange={onChangeHandler}
                    placeholder="Enter password"
                    className={`bg-gray-50 border ${apiError ? "border-red-500" : "border-black"
                      } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-black dark:placeholder-gray-700 dark:text-black dark:focus:ring-black dark:focus:border-black`}
                    required
                  />
                  {apiError && (
                    <p className="text-red-500">{apiError}</p>
                  )}
                </div>
                <div className="items-center justify-between signin-rememberme">
                  <div className="flex items-center">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required=""
                      />
                    </div>
                    <div className="ml-3 text-sm card-remember">
                      <label
                        htmlFor="remember"
                        className="text-black dark:text-black"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>

                  <Link to="/student/resetpass">
                    <p
                      href="#"
                      className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                    >
                      Forgot password?
                    </p>
                  </Link>
                </div>
                {apiError && <Alert type="error">{apiError}</Alert>}
                <button
                  type="submit"
                  className="w-full text-black bg-orange-400  hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Sign in
                </button>
              </form>
              <button
                onClick={closePopup}
                className="w-full text-black bg-white border-2 border-solid border-black hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Close
              </button>
              <div className="text-sm text-center mt-4">
                Don't have an account?{" "}
                <span
                  onClick={handleSignUpClick}
                  className="text-primary-600 hover:underline cursor-pointer"
                >
                  Sign Up
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
