import React, { useState, useEffect } from "react";
import { FaUser, FaEnvelope, FaMobile, FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { registerAPI, sendOTP, verifyOTP } from "../../apis/AuthAPI";
import './Signup.css';

const RegistrationPopup = ({ onClose, openLogin }) => {
  const navigate = useNavigate();
  const [file, setSelectedFile] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [timer, setTimer] = useState(120);
  const [formData, setFormData] = useState({
    empName: "",
    password: "",
    email: "",
    number: "",
    companyAddress: "",
    description: "",
    accountHolderName: "",
    company_website_url: "",
    cin_number: "",
    privacy_policy: "",
  });

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [otpSent, timer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSendOtp = async () => {
    setOtpLoading(true);

    try {
      let data = {
        email: formData.email
      }
      const res = await sendOTP(data);
      if (res.status === 200) {
        setOtpSent(true);
        alert("OTP sent");
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("Error sending OTP");
    } finally {
      setOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setVerifyLoading(true);
    try {
      let data = {
        otp,
        email: formData.email
      }
      const res = await verifyOTP(data)
      if (res.status === 200) {
        alert("OTP verified successfully");
        setOtpSent(false);
        setOtp("");
        setIsVerified(true);

      } else {
        alert(res.data.message);
      }

    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("Error verifying OTP");
    } finally {
      setVerifyLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isVerified) {
      alert("Please verify your OTP before submitting the form.");
      return;
    }
    try {
      const data = new FormData();
      data.append("file", file);
      data.append("data", JSON.stringify(formData));

      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }
      const res = await registerAPI(data, header);

      if (res.status === 200) {
        const responseData = res.data.data;
        localStorage.setItem("empName", responseData.empName);
        localStorage.setItem("token", responseData.token);
        localStorage.setItem("email", responseData.email);
        localStorage.setItem("userId", responseData.userId);
        localStorage.setItem("number", responseData.number);
        localStorage.setItem("active", false);

        let count = {
          searches: responseData.searches,
          internshipEnquiry: responseData.internshipEnquiry,
          verifiedApplication: responseData.verifiedApplication,
          ResumeView: responseData.ResumeView
        };
        count = JSON.stringify(count);
        localStorage.setItem('count', count)
        alert(res.data.message);
        navigate("/employer/privacypolicy");
      } else if (res.status === 203) {
        alert(res.data.message);
      } else {
        console.error("Registration failed with unknown error");
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">

      <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full overflow-auto">
        <h1 className="text-3xl font-semibold mb-4 text-center">Employer Registration</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Employer Name</label>
              <div className="flex items-center mt-1">
                <FaUser className="mr-2" size={'20px'} />
                <input
                  type="text"
                  name="empName"
                  value={formData.empName}
                  onChange={handleChange}
                  className="p-2 border rounded w-full"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <div className="flex items-center mt-1">
                <FaEnvelope className="mr-2" size={'20px'} />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="p-2 border rounded w-full"
                />
                <button
                  type="button"
                  onClick={handleSendOtp}
                  className={`ml-2 p-2 rounded ${isVerified ? "bg-green-600 text-white" : "bg-black text-white text-sm"}`}
                  disabled={otpLoading || isVerified}
                >
                  {otpLoading ? "Sending..." : isVerified ? "Verified" : "Send OTP"}
                </button>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Mobile Number</label>
              <div className="flex items-center mt-1">
                <FaMobile className="mr-2" />
                <input
                  type="text"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  className="p-2 border rounded w-full"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <div className="flex items-center mt-1">
                <FaLock className="mr-2" />
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="p-2 border rounded w-full"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Company Address</label>
              <input
                type="text"
                name="companyAddress"
                value={formData.companyAddress}
                onChange={handleChange}
                className="p-2 border rounded w-full mt-1"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="p-2 border rounded w-full mt-1"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Account Holder Name</label>
              <input
                type="text"
                name="accountHolderName"
                value={formData.accountHolderName}
                onChange={handleChange}
                className="p-2 border rounded w-full mt-1"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Company Website</label>
              <input
                type="text"
                name="company_website_url"
                value={formData.company_website_url}
                onChange={handleChange}
                className="p-2 border rounded w-full mt-1"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">CIN Number</label>
              <input
                type="text"
                name="cin_number"
                value={formData.cin_number}
                onChange={handleChange}
                className="p-2 border rounded w-full mt-1"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Privacy Policy</label>
              <input
                type="text"
                name="privacy_policy"
                value={formData.privacy_policy}
                onChange={handleChange}
                className="p-2 border rounded w-full mt-1"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Upload File</label>
              <input
                type="file"
                onChange={handleFileChange}
                className="p-2 border rounded w-full mt-1"
              />
            </div>

            {otpSent && !isVerified && (
              <div className="col-span-1 md:col-span-2">
                <label className="block text-sm font-medium text-gray-700">OTP</label>
                <div className="flex items-center mt-1">
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="p-2 border rounded w-full"
                  />
                  <button
                    type="button"
                    onClick={handleVerifyOtp}
                    className="ml-2 p-2 text-sm bg-blue-600 hover:bg-blue-700 text-white rounded"
                    disabled={verifyLoading}
                  >
                    {verifyLoading ? "Verifying..." : "Verify OTP"}
                  </button>
                </div>
                <p className="text-gray-500 mt-2">
                  OTP expires in {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                </p>
              </div>
            )}
          </div>
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="p-2 bg-gray-500 text-white rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="p-2 bg-orange-400 hover:bg-orange-500 text-white rounded"
              disabled={!isVerified}
            >
              Register
            </button>
          </div>
        </form>
        <p className="text-sm font-light text-gray-500 dark:text-black">
          Already have an account?

          <a onClick={() => { openLogin(); onClose() }} className="font-medium text-primary-600 hover:underline dark:text-primary-500">
            Log in
          </a>

        </p>
      </div>
    </div>
  );
};

export default RegistrationPopup;
