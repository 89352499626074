import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../Footer";
// import Internal_Navbar from "../InternalNavbar";
// import AppliedInternNavBar from "../AppliedInternNavBar/Applied_Intern_Internal_Navbar";
// import Applied_Intern_Internal_Navbar from "../AppliedInternNavBar/Applied_Intern_Internal_Navbar";
import "../ResponsiveCss/ResponsiveCss.css";
import Internal_Navbar from "../UpdatedNav/Internal_Navbar";
import axios from "axios";
import { config } from "../../service/service";

const MonthlyPackages = () => {
  const [monthlyPackage, setMonthlyPackage] = useState([]);
  const [endDate, setendDate] = useState();
  const [active, setActive] = useState()
  const [token, setToken] = useState();
  useEffect(() => {
    const savedtoken = localStorage.getItem('token');
    setToken(savedtoken)
  }, []);
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${config.backend_url}/api/students/students-free-package`
      );
      const data = await response.json();
      setMonthlyPackage(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchEndDate = async () => {
    try {
      const res = await axios.get(`${config.backend_url}/api/newstudents/getuser`, {
        headers: {
          'Authorization': token
        }
      })
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };
      const formattedDate = formatDate(res.data.data.freemium.endDate)
      setendDate(formattedDate)
      setActive(res.data.data.freemium.active)
    } catch (error) {
      console.error('Error fetching End date:', error);

    }
  }

  useEffect(() => {
    fetchData();
    fetchEndDate()
  }, []);

  const handleSubscribe = async () => {
    const userId = localStorage.getItem("userId");

    if (!window.confirm("Are you sure you want to subscribe?")) {
      return;
    }

    try {
      console.log(localStorage.getItem('token'))
      // Perform the patch request to update user's data
      const data = {}
      const response = await axios.patch(
        `${config.backend_url}/api/newstudents/freemium`, data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token')
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.msg);
        // Refresh the freePackage data
        fetchData();
        // updating the local storage value
        console.log(response.data.data)
        localStorage.setItem('count', JSON.stringify(response.data.data))

        // Log the updated freePackage object
        console.log("Updated freePackage:",);
        window.location.assign('/freeplan');
      }
      else if (response.status === 202) {
        alert(response.data.msg)
      }

      else {
        console.error("Failed to subscribe:",);
      }
    } catch (error) {
      console.error("Error during subscription:", error);
    }
  };

  return (
    <>
      {/* <div><Internal_Navbar/></div>  */}
      <Internal_Navbar />

      <section className="text-black body-font flex">
        {/* <div><Sidebar /></div> */}
        <div className="container px-5 py-5 mx-auto ">
          <div className="flex flex-col text-center w-full mt-20">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-black">
              Monthly Packages
            </h1>
            {/* <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-black">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical.</p> */}
            <div className="flex mx-auto border-2 border-amber-300 rounded overflow-hidden mt-6">
              <Link to={"/student/freeplan"}>
                <button className={`py-1 px-4 text-black focus:outline-none`}>
                  Freemium
                </button>
              </Link>
              <Link to={"/student/monthlyplan"}>
                <button
                  className={`py-1 px-4 text-black focus:outline-none border-b-2 border-indigo-600`}
                  title="This functionality is under development"
                >
                  Premium
                </button>
              </Link>
            </div>
          </div>

          {monthlyPackage && (
            monthlyPackage.map((data, i) => {
              return (
                <div key={i} className=" width-set-for-the-premium-or-freemium-packages mx-auto">
                  <div className="Freecard p-4 xl:w-full w-full">
                    <div className="Freecard h-full p-6 rounded-lg flex flex-col relative overflow-hidden">
                      <div className="Freecard p-4 w-full">
                        <div className="Freecards h-full p-6 rounded-lg border-2 border-amber-300 flex flex-col relative overflow-hidden">
                          <span className="bg-black text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                            POPULAR
                          </span>
                          <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                            PRO
                          </h2>
                          <h1 className="text-5xl text-black leading-none flex items-center pb-4 mb-4 border-b border-amber-300">
                            <span className="preeHeading ">{`₹${data.freePackagePrice}`}</span>
                            <span className="text-lg ml-1 font-normal text-black">
                              /mo
                            </span>
                          </h1>
                          <p className="flex items-center text-black mb-2">
                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                              <svg
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2.5"
                                className="w-3 h-3"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20 6L9 17l-5-5"></path>
                              </svg>
                            </span>
                            {data.searches} Searches
                          </p>

                          <p className="flex items-center text-black mb-2">
                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                              <svg
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2.5"
                                className="w-3 h-3"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20 6L9 17l-5-5"></path>
                              </svg>
                            </span>
                            {data.opportunities} Opportunities
                          </p>

                          <p className="flex items-center text-black mb-2">
                            {" "}
                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                              <svg
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2.5"
                                className="w-3 h-3"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20 6L9 17l-5-5"></path>
                              </svg>
                            </span>
                            {data.verified_application} Verified Apply
                          </p>

                          <p className="flex items-center text-black mb-6">
                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                              {" "}
                              <svg
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2.5"
                                className="w-3 h-3"
                                viewBox="0 0 24 24"
                              >
                                <path d="M20 6L9 17l-5-5"></path>
                              </svg>
                            </span>
                            {data.dedicated_crm}
                          </p>

                          {active ? <button
                            onClick={handleSubscribe}
                            className="flex items-center mt-auto text-black bg-amber-300 border-0 py-2 px-4 w-full focus:outline-none hover:bg-amber-400 rounded"
                          >
                            Subscribe
                            <svg
                              fill="none"
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              className="w-4 h-4 ml-auto"
                              viewBox="0 0 24 24"
                            >
                              <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                          </button> :

                            <button
                              onClick={() => navigate('/student/monthlyplan')}
                              className="flex items-center mt-auto text-black bg-amber-300 border-0 py-2 px-4 w-full focus:outline-none hover:bg-amber-400 rounded"
                            >
                              Upgrade
                              <svg
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                className="w-4 h-4 ml-auto"
                                viewBox="0 0 24 24"
                              >
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                              </svg>
                            </button>
                          }
                          <div>
                            {

                              active ?
                                <p className="text-xs text-black  mt-3">Your Subscription will end on <span className='font-bold text-sm'>{endDate}.</span></p>
                                : <p></p>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })

          )}
        </div>
      </section>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default MonthlyPackages;
