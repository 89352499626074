import React, { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const RefundModal = ({ isOpen, onClose, onRefund, date, amount, payment_id }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleRefund = async () => {
    setIsLoading(true);
    await onRefund();
    setIsLoading(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <h2 className="text-2xl mb-4">Refund Request</h2>
        {payment_id ? (
          <div className="mb-4">
            <p><strong>Payment ID:</strong> {payment_id}</p>
            <p><strong>Refund Date:</strong> {new Date(date).toLocaleDateString('en-IN')}</p>
            <p><strong>Refund Amount:</strong> ₹{amount}</p>
          </div>
        ) : (
          <div className="mb-4 text-red-500">
            No Transaction is Done.
          </div>
        )}
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleRefund}
            className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
            disabled={!payment_id || isLoading}
          >
            {isLoading ? <ClipLoader size={20} color={"#ffffff"} /> : 'Refund'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RefundModal;
