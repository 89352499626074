import React from 'react';
import {Navigate} from "react-router-dom";

export const EmployerProtectedRoute = ({element}) => {

    const token = localStorage.getItem("userId");
    return token ? element : <Navigate to={"/"}/>;
 
}
export const StudentProtectedRoute = ({element}) => {

    const token = localStorage.getItem("userId");
    return token ? element : <Navigate to={"/"}/>;
 
}
