import axios from "axios";
// Function to get headers with the latest token
const getHeader = () => {
  const token = localStorage.getItem("token");
  return {
    Authorization: token,
  };
};

// Create an Axios instance with default settings
const apiClient = axios.create({
  baseURL: "https://backend.internsbee.in",
  // timeout: 5000,
});

// Use an interceptor to dynamically set the headers before each request
apiClient.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    ...getHeader(),
  };
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Custom error handler
const handleError = (error) => {
  if (error.response) {
    console.error("Server responded with an error:", error.response.data);
  } else if (error.request) {
    console.error("No response received:", error.request);
  } else {
    console.error("Error setting up request:", error.message);
  }
  throw error;
};

// POST request with custom headers
const createIntership = async (data, headers = {}) => {
  try {
    const endpoint = "/api/newemployee/postintership";
    const response = await apiClient.post(endpoint, data, { headers });
    return response;
  } catch (error) {
    handleError(error);
  }
};

const update = async (data) => {
  try {
    const endpoint = "/api/newemployee/update";
    const response = await apiClient.patch(endpoint, data);
    return response;
  } catch (error) {
    handleError(error);
  }
};

export {
  createIntership,
  update
};
