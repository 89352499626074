import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../Student/Assets/white_header1.png';
import '../Student/Components/ResponsiveCss/ResponsiveCss.css';
import '../Student/Components/Navbar.css';
import Login from '../Student/Components/Signin';
import Registration from "../Student/Components/Registration";
import EmployerRegistration from '../Employer/Component/Signup/Signup';
import EmployerLogin from "../Employer/Component/Login/Login";

const Navbar = () => {

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);



    const [loginStudentPopup, setLoginStudentPopup] = useState(false);
    const [registerStudentPopup, setRegisterStudentPopup] = useState(false);
    const [loginEmployerPopup, setLoginEmployerPopup] = useState(false);
    const [registerEmployerPopup, setRegisterEmployerPopup] = useState(false);

    const openLoginPopup = () => setLoginStudentPopup(true);
    const closeLoginPopup = () => setLoginStudentPopup(false);

    const openRegisterPopup = () => setRegisterStudentPopup(true);
    const closeRegisterPopup = () => setRegisterStudentPopup(false);
    // Employer
    const openELoginPopup = () => setLoginEmployerPopup(true);
    const closeELoginPopup = () => setLoginEmployerPopup(false);

    const openERegisterPopup = () => setRegisterEmployerPopup(true);
    const closeERegisterPopup = () => setRegisterEmployerPopup(false);

    return (
        <div className="mb-10">
            <div className="navbar-container fixed top-0 left-0 w-full z-50 bg-white shadow-md flex items-center justify-between border">
                <div className="flex items-center space-x-2">
                    <Link to={'/'}>
                        <div className='navbar-logo'>
                            <img src={logo} alt="Logo" className="navbar-logo-for-internsbeestudent w-[12rem] rounded-full" />
                        </div>
                    </Link>
                    <button className="mobile-menu-button md:hidden" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>
                    <div className={`nav-links md:flex ${isMobileMenuOpen ? 'block' : 'hidden'} md:block`}>
                        <div className='flex gap-4 p-2'>
                            <Link to='/'>
                                <p className='hover:text-amber-500'>Home</p>
                            </Link>
                            <Link to='/student/aboutus'>
                                <p className='hover:text-amber-500'>About Us</p>
                            </Link>
                            <Link to='/student/blogs'>
                                <p className='hover:text-amber-500'>Blogs</p>
                            </Link>
                            <Link to='/student/LandingInternship'>
                                <p className='hover:text-amber-500'>Internship</p>
                            </Link>
                            <Link to="/student/TPO">
                                <p className='hover:text-amber-500'>TPO</p>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className={`login-register mr-14 md:flex ${isMobileMenuOpen ? 'block' : 'hidden'} md:block`}>
                    <div className='relative'>
                        <ul className="menu clearfix">
                            <li className="parent p-2">
                                <div>Login</div>
                                <ul className="children">
                                    <li><a onClick={openELoginPopup}>Employer</a></li>
                                    <li><a onClick={openLoginPopup}>Student</a></li>
                                </ul>
                            </li>
                            <li className="parent p-2">
                                <div>Register</div>
                                <ul className="children">
                                    <li><a onClick={openERegisterPopup}>Employer</a></li>
                                    <li><a onClick={openRegisterPopup}>Student</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Student */}
            {loginStudentPopup && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="">

                        <Login
                            closePopup={closeLoginPopup}
                            openRegister={openRegisterPopup}
                        />
                    </div>
                </div>
            )}
            {registerStudentPopup && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="">
                        <Registration closePopup={closeRegisterPopup} openLogin={openELoginPopup} />
                    </div>
                </div>
            )}

            {/* Employer */}
            {loginEmployerPopup && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="">
                        <EmployerLogin closePopup={closeELoginPopup}
                            openRegister={openERegisterPopup} />
                    </div>
                </div>
            )}
            {registerEmployerPopup && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="">
                        <EmployerRegistration onClose={closeERegisterPopup} openLogin={openELoginPopup} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
